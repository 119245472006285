<template>
    <div class="view pa24">
      <!-- <el-button @click="createHandle" type="primary" size="medium">
        +创建空白码
      </el-button> -->
    <div>
      <el-row>
        <el-col :span="7">
          <el-form label-width="100" :inline="true">
        <el-form-item label="批次号">
          <el-input v-model="lotNumber"></el-input>
        </el-form-item>
        <el-button size="medium" type="primary" @click="handleSearchData">搜索</el-button>
        <el-button size="medium" @click="handleReset">重置</el-button>
      </el-form>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="medium" style="margin-bottom: 20px;" @click="goSearchHandle" icon="el-icon-search">搜索标识</el-button>
        </el-col>
      </el-row>

    </div>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center"  prop="lotNumber" label="批次号"/>
      <el-table-column align="center"  prop="num" label="数量"/>
      <el-table-column align="center"  prop="activeNum" label="已激活数量" />
      <el-table-column align="center"  prop="requirePass" label="是否需要验证">
        <template slot-scope="scope">
          {{ !scope.row.requirePass ? '否' : '是' }}
        </template>
      </el-table-column>
      <el-table-column align="center"  prop="createTime" label="创建时间"/>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
            <el-button type="text" @click="goRytList(scope.row)">查看</el-button>
            <el-button type="text" @click="exportExcel(scope.row)">导出</el-button>
        </template>
      </el-table-column>
      </template>
    </commonTable>


    <!-- 标识检索 -->
    <el-dialog title="标识检索" :visible.sync="searchVisible" append-to-body :close-on-click-modal="false">
      <el-form label-width="100" :inline="true">
        <el-form-item label="标识">
          <el-input v-model="handle" clearable></el-input>
        </el-form-item>
        <el-button size="medium" type="primary" @click="handleSearch">搜索</el-button>
      </el-form>
      <div>
        <commonTable
          :tableData="searchData"
          @handleSizeChange="searchHandleSizeChange"
          @handleCurrentChange="searchHandleCurrentChange"
          :currentPage="searchNum"
          :loading="searchLoading"
          :total="searchTotal"
        >
        <template v-slot:table>
          <el-table-column :show-overflow-tooltip="true" prop="title" label="标题" align="center"/>
          <el-table-column prop="title" label="二维码" align="center">
            <template slot-scope="scope">
              <vue-qr
                  :size="50"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="`https://api.86122m.com/ryt?ryt=${scope.row.handle}`"
                  class="codeImage"
              />
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" prop="handle" label="标识" align="center"/>
          <el-table-column :show-overflow-tooltip="true" prop="lotNumber" label="批次号" align="center"/>
          <el-table-column prop="password" label="激活码" align="center"/>
          <el-table-column prop="nickName" label="管理员" align="center"/>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              {{scope.activeTime?'已激活':'未激活'}}
            </template>
          </el-table-column>
        </template>
        </commonTable>
      </div>
    </el-dialog>

    <!--创建空白码-->
    <!-- <CreateHandleDialog ref="createHandleDialog"></CreateHandleDialog> -->

    </div>

</template>
<script>
import commonTable from "@/components/common/commonTable";
import {fetchRythtPage , fetchCreateBatchPage, fetchRytPageBatchList,fetchZdyRytExport,fetchCompanyUserList} from '@/api/ryt.js'
// import CreateHandleDialog from './components/CreateHandleDialog'
import VueQr from 'vue-qr'
export default {
  name: "rytHandle",
  components: {
    commonTable,
    VueQr,
    // CreateHandleDialog
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      lotNumber:'',
      handle:'',
      loading:false,
      searchVisible:false,
      searchHandle: '',
      searchData: [],
      searchNum: 1,
      searchSize: 10,
      searchTotal: 0,
      searchLoading:false
    }
  },
  created() {
      this.getTableData()
  },
  methods: {
    getUserList(){

    },
    goSearchHandle() {
      this.searchVisible = true,
      this.handle = ''
      this.searchNum = 1
      this.searchTotal = 0
      this.searchData = []
    },
    handleReset() {
      this.lotNumber = ''
      this.handle = ''
      this.pageNum = 1
      this.getTableData()
    },
    handleSearchData() {
      this.pageNum = 1
      this.getTableData()
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    searchHandleSearchData() {
      this.pageNum = 1
      this.handleSearch()
    },
    searchHandleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.handleSearch();
    },
    searchHandleCurrentChange(num) {
      this.pageNum = num;
      this.handleSearch();
    },
    handleSearch() {
      let params = {
        handle: this.handle,
        pageNum: this.searchNum,
        pageSize: this.searchSize
      }
      fetchRythtPage(params).then(res => {
        this.searchData = res.list
        this.searchTotal = res.total
      })
    },
    getTableData() {
      let params = {
        handle: this.handle,
        lotNumber: this.lotNumber,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      fetchRytPageBatchList(params).then((res) => {
        this.tableData = res.list;
        this.total = res.total
      });
    },
    goRytList(data) {
      let queryData = {}
      if (data) {
        queryData.batchId = data.id
      } else {
        queryData.handle = this.handle
      }
      this.$router.push({
        path: '/ryt/handleIndex',
        query: queryData,
      })
    },
    exportExcel(data) {
      let params = {
        lotNumber: data.lotNumber
      }
      fetchZdyRytExport(params).then(res => {
        // let ss = decodeURIComponent(res.fileName)
        // let aa = ss.indexOf('=')
        // let fileNmae = ss.substring(aa + 1)
        this.downloadBlobFile(res, `${data.lotNumber}.xlsx`, 'text/plain;charset=utf-8')
      })
    },
    downloadBlobFile(data, name, type) {
      if (window.navigator.msSaveOrOpenBlob) {
        // 兼容IE
        try {
          var blobObject = new Blob([encodeURI(data)]);
          window.navigator.msSaveOrOpenBlob(blobObject, fileName);
        } catch (e) {
          console.log(e);
        }
      } else {
        var blob = new Blob([data], {
          // type: "application/octet-stream;charset=UTF-8"
          type: type
        });
        var downloadElement = document.createElement("a");
        // 下载的⽂件名
        downloadElement.download = name;
        // 创建下载的链接
        downloadElement.href = window.URL.createObjectURL(blob);
        // 点击下载
        downloadElement.click();
        document.body.appendChild(downloadElement);
        // 下载完成移除元素
        document.body.removeChild(downloadElement);
        // 释放掉blob对象
        window.URL.revokeObjectURL(downloadElement.href);
      }
    },
    // 创建空白码
    createHandle() {
      this.$refs.createHandleDialog.openDialog()
    }
  }
}
</script>
<style scoped>
.avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

</style>
